import firebase from "firebase/app";
import "firebase/analytics";

let config = {
    apiKey: "AIzaSyCcG5yeuJMazcn5BtfeXvRI1_ePAag1Z2U",
    authDomain: "itesaofflineform.firebaseapp.com",
    projectId: "itesaofflineform",
    storageBucket: "itesaofflineform.appspot.com",
    messagingSenderId: "380213615967",
    appId: "1:380213615967:web:714a1ee4223a8ff31f2aea",
    measurementId: "G-EDQWVNR0PH"
};

firebase.initializeApp(config);
firebase.analytics();

export default firebase;
