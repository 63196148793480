import {Form, Input, Button, Checkbox, DatePicker, message, Select, Modal} from 'antd';
import styles from './theform.module.scss'
import firebase from '../firebase'
import React, {Fragment, useState} from 'react';
import 'firebase/firestore'

import locale from 'antd/es/date-picker/locale/es_ES';
const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 0,
        span: 24,
    },
};

export const TheForm = () => {
    const [form] = Form.useForm();
    const [loadingCloud, setLoadingCloud] = useState(false)

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    const onFinish = (values) => {
        console.log('Success:', values);
        let arr = JSON.parse(localStorage.getItem('users'));
        arr = arr ? arr : [];
        arr.push(values)
        console.log(arr)
        localStorage.setItem('users', JSON.stringify(arr));
        form.resetFields();
        message.success('Datos guardados!')

    };

    const uploadCloud = async () => {
        setLoadingCloud(true)
        let online = window.navigator.onLine;
        if (online){
            let arr = JSON.parse(localStorage.getItem('users'));
            arr = arr ? arr : [];
            let db = firebase.firestore();
            for (let i = 0; i < arr.length; i++) {
                let user = arr[i];
                await db.collection('users').add(user)
            }
            localStorage.clear();
            setLoadingCloud(false)
            message.success('Datos subidos!')
        }else {
            setLoadingCloud(false)
            message.error('No hay conexion a internet')
        }


    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Fragment>
            <Form
                form={form}
                className={styles.theForm}
                {...layout}
                name="basic"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    label="Lugar"
                    name="place"
                    rules={[
                        {
                            required: false,
                            message: 'Porfavor seleccione el lugar',
                        },
                    ]}
                >
                    <Select>
                        <Select.Option value="Arcos">Arcos</Select.Option>
                        <Select.Option value="Alcorta">Alcorta</Select.Option>
                        <Select.Option value="Unicenter">Unicenter</Select.Option>
                        <Select.Option value="Rosario">Rosario</Select.Option>
                        <Select.Option value="Cordoba">Cordoba</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Nombre"
                    name="name"
                    rules={[
                        {
                            required: false,
                            message: 'Porfavor ingrese su nombre',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Apellido"
                    name="Lastname"
                    rules={[
                        {
                            required: false,
                            message: 'Porfavor ingrese su apellido',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>


                <Form.Item
                    label="E-mail"
                    name="email"
                    rules={[
                        {
                            type: 'email',
                            required: false,
                            message: 'Porfavor ingrese su e-mail',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Fecha de nacimiento"
                    name="birthday"
                    rules={[
                        {
                            required: false,
                            message: 'Porfavor ingrese su fecha de nacimiento',
                        },
                    ]}
                >
                    <DatePicker locale={locale} className={styles.datePicker}/>
                </Form.Item>

                <Form.Item {...tailLayout} name="accept" valuePropName="checked"
                           rules={[
                               {
                                   required: true,
                                   message: 'Porfavor acepte los terminos y condiciones',
                               },
                           ]}
                >
                    <Checkbox> Acepto <a onClick={showModal} href={'#'}>terminos y condiciones</a></Checkbox>
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button block type="primary" htmlType="submit">
                        Enviar
                    </Button>
                </Form.Item>
                <Form.Item {...tailLayout}>
                    <Button loading={loadingCloud} onClick={uploadCloud} block >
                        Actualizar nube
                    </Button>
                </Form.Item>
            </Form>
            <Modal
                title="Terminos y condiciones"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <p>Acepto y autorizo expresa, gratuita y voluntariamente que los datos aquí
                    consignados por mí sean almacenados, procesados y utilizados por NATURA
                    COSMETICOS S.A. (“Natura”) y sus empresas relacionadas, filiales y matrices
                    a fin de contactarse conmigo con fines informativos, promocionales,
                    publicitarios, marketing directo, comerciales y/o similares en el futuro. Los
                    datos que proporciono son adecuados y completos. Asimismo, acepto y
                    autorizo a que Natura comparta los datos con proveedores locales o
                    extranjeros -incluso en países donde las leyes de protección de datos pueden
                    ser diferentes a las de Argentina- que provean a Natura servicios de desarrollo
                    y mantenimiento de sitios web y de campañas de marketing y/o envío de SMS
                    o similares. Entiendo y acepto que Natura procesará mis datos siguiendo
                    estrictas normas de seguridad y confidencialidad y que tengo derecho a
                    acceder, rectificar, actualizar y suprimir mis datos de acuerdo a la normativa
                    aplicable, como así también solicitar en cualquier momento el retiro o bloqueo
                    total o parcial de mis datos de la base de datos de Natura, a cuyos fines podré
                    comunicarme a la siguiente dirección de correo electrónico:
                    can_arg_coordinacion@natura.net. El titular de los datos personales tiene la facultad
                    de ejercer el derecho de acceso a los mismos en forma gratuita a
                    intervalos no inferiores a seis meses, salvo que se acredite un
                    interés legítimo al efecto conforme lo establecido en el artículo 14,
                    inciso 3 de la Ley Nº 25.326. La AGENCIA DE ACCESO A LA
                    INFORMACIÓN PÚBLICA, Órgano de Control de la Ley Nº 25.326,
                    tiene la atribución de atender las denuncias y reclamos que se
                    interpongan con relación al incumplimiento de las normas sobre
                    protección de datos personales.</p>
            </Modal>
        </Fragment>

    );
};